import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({

    providedIn: 'root'
})
export class SpinnerService {
    private config: any;
    private fullScreenConfig: any;
    private withoutBackgroundConfig: any;

    constructor(private ngxSpinnerService: NgxSpinnerService) {
        this.config = {
            type: 'line-scale-party',
            size: "default",
            color: "black",
            bdColor: "rgba(255, 255, 255, 0.95)",
            fullScreen: false,
            template: "<img src='https://assets2.lottiefiles.com/private_files/lf30_kesdidxl.json'/>"
        };

        this.fullScreenConfig = {
            type: 'line-scale-party',
            bdColor: "rgba(255, 255, 255, 0.95)",
            fullScreen: true
        };

        this.withoutBackgroundConfig = {
            type: 'line-scale-party',
            bdColor: "transparent",
            fullScreen: false
        }
    }

    spin = (name?: string, withoutBackground?: boolean) => {
        if (withoutBackground) {
            this.ngxSpinnerService.show(name, this.withoutBackgroundConfig);
            return;
        }
        if (name === 'lottie-fullscreen-loader') {
            this.ngxSpinnerService.show(name, this.fullScreenConfig);
        } else {
            this.ngxSpinnerService.show(name, this.config);
        }
    }

    stop = (name?: string) => {
        this.ngxSpinnerService.hide(name);
    }
}
